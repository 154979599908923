


































































import { Observer } from 'mobx-vue'
import { Component, Vue, Inject } from 'vue-property-decorator'
import MintViewModel from '../viewmodels/mint-viewmodel'
import { walletStore } from '@/stores/wallet-store'

@Observer
@Component({
  components: {
    CharacterCard: () => import('@/components/character-card.vue'),
  },
})
export default class Mint extends Vue {
  @Inject() vm!: MintViewModel
  wallet = walletStore

  closeDialog() {
    this.vm.setOpenMintDialog(false);
    this.vm.resetNFTMintAmount()
  }
}
